import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    notificationId: PropTypes.number,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    submitting: null,
    notificationId: null
  }

  render () {

    const {
      submitting,
      notificationId,
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <div className={styles.footer}>
      
        <Button
          type="default"
          disabled={submitting}
          onClick={onClickCancel}
        >
          {notificationId ? 'Close' : 'Cancel'}
        </Button>
        
        {!notificationId && (

          <Button
            type="primary"
            loading={submitting}
            onClick={onClickSubmit}
          >
            {'Create'}
          </Button>
        )}

      </div>

    )
  }
}