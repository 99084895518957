import { combineReducers } from "redux";

import { reducer as schools } from "./State/schools";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showSchoolModal } from "./State/showSchoolModal";
import { reducer as selectedSchoolId } from "./State/selectedSchoolId";

export default combineReducers({
  schools,
  pagination,
  searchTerm,
  fetching,
  showSchoolModal,
  selectedSchoolId
});
