import handleException from '../../../Core/Helpers/handleException';
import { setFetching } from '../State/fetching';

import { getNotifications } from '../../../HttpRequests/notifications';
import { loadNotifications } from '../State/notifications';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Notifications;
  const { user } = getState();

  try {

    const params = {};
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (user.type === 'School') {
      params['filter[school_id]'] = user.school_id;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.include = ["school"].join();

    dispatch(setFetching(true));

    const response = await getNotifications({ params });
    const notifications = response.data;

    dispatch(loadNotifications(notifications.data));
    
    dispatch(setPagination({
      total: notifications.pagination.total,
      pageSize: notifications.pagination.per_page,
      current: notifications.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}