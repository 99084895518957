import { createAction, handleActions } from 'redux-actions';

const setShowNotificationModal = createAction("SCREENS/NOTIFICATIONS/SET_SHOW_NOTIFICATION_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowNotificationModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowNotificationModal, reducer };