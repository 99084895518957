import { setPagination } from '../../../State/pagination';
import fetchNotifications from '../../../Services/fetchNotifications';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchNotifications());

}