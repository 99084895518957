import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd'
import moment from 'moment';

export default class extends React.PureComponent {

  static propTypes = {
    notifications: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,

    onChangeTable: PropTypes.func.isRequired,
    onClickView: PropTypes.func.isRequired
  }

  render() {

    const {
      notifications,
      pagination,
      fetching,

      onChangeTable,
      onClickView
    } = this.props;
    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'school.name',
        render: (text, record) => (
          <a role="presentation" onClick={() => onClickView(record)}>
            { text }
          </a>
        )
      },
      
      {
        title: 'Title',
        dataIndex: 'title'
      },

      {
        title: 'Message',
        dataIndex: 'message',
        render: (text) => (
          <span>
            { text.length > 40 ? `${text.substr(0, 40)}...` : text }
          </span>
        )
      },

      {
        title: 'Sent at',
        dataIndex: 'created_at',
        render: (text) => (
          <span>
            { text ? moment(text).format("YYYY-MM-DD") : '--' }
          </span>
        )
      }
    ]
    return (
      <Table
        dataSource={notifications}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />
    )
  }
}