import { createAction, handleActions } from 'redux-actions';

const loadSchools = createAction("CORE/SCHOOLS/LOAD_SCHOOLS");

const defaultState = []

const reducer = handleActions(
  {
    [loadSchools]: (state, action) => action.payload
  },
  defaultState
);

export { loadSchools, reducer };