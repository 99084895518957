import { setPagination } from '../../../State/pagination';
import fetchSchools from '../../../Services/fetchSchools';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchSchools());

}