export default (values) => {

  const errors = {};
  const regUrl = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;

  if (!values.name) errors.name = true;
  if (!values.wordpress_url) errors.wordpress_url = true;

  if (values.wordpress_url && !regUrl.test(values.wordpress_url)) errors.wordpress_url = "Invalid URL";
  
  if (values.calendar_url_ios && !regUrl.test(values.calendar_url_ios)) errors.calendar_url_ios = "Invalid URL";
  if (values.calendar_url_android && !regUrl.test(values.calendar_url_android)) errors.calendar_url_android = "Invalid URL";
  return errors;
  
}