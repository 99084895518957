import { connect } from 'react-redux';

import userIsSchool from '../../../../Core/Selectors/userIsSchool';

import Component from './Component';

const mapStateToProps = (state) => ({
  schools: state.core.schools,
  userIsSchool: userIsSchool(state)
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Component);