import { initialize } from 'redux-form';

import { getSchool } from '../../../HttpRequests/schools';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { schoolId } = getProps();
  
  if (schoolId) {

    const response = await getSchool({ id: schoolId });

    const school = response.data.data;

    dispatch(initialize(FORM_NAME, school))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}