import { message } from 'antd';

import { createNotification } from '../../../HttpRequests/notifications';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    await createNotification({ data });

    message.success('Notification Created');

    return true;
    
  }  catch(error) {
    
    handleException(error);

    return false;

  }
}
