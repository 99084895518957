export default (values) => {

  const errors = {};

  if (!values.school_id) errors.school_id = true;
  if (!values.title) errors.title = true;
  if (!values.message) errors.message = true;
  
  return errors;
  
}