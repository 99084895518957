import { createAction, handleActions } from 'redux-actions';

const setSelectedSchoolId = createAction("SCREENS/SCHOOLS/SET_SELECTED_SCHOOL_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedSchoolId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedSchoolId, reducer };
