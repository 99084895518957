import { combineReducers } from "redux";

import { reducer as notifications } from "./State/notifications";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showNotificationModal } from "./State/showNotificationModal";
import { reducer as selectedNotificationId } from "./State/selectedNotificationId";

export default combineReducers({
  notifications,
  pagination,
  searchTerm,
  fetching,
  showNotificationModal,
  selectedNotificationId
});
