import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectField, InputField, TextAreaField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    schools: PropTypes.array.isRequired,
    userIsSchool: PropTypes.bool.isRequired,
    notificationId: PropTypes.number
  }

  static defaultProps = {
    notificationId: null
  }

  render () {

    const {
      submitting,
      schools,
      userIsSchool,
      notificationId
    } = this.props;

    const schoolOptions = schools.map(item => ({value: item.id, label: item.name }))

    return (
      
      <React.Fragment>

        <Field
          name="school_id"
          label="School"
          disabled={submitting || !!notificationId || userIsSchool}
          component={SelectField}
          options={schoolOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="title"
          label="Title"
          disabled={submitting || !!notificationId}
          component={InputField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="message"
          label="Message"
          disabled={submitting || !!notificationId}
          component={TextAreaField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

      </React.Fragment>

    )
  }
}