import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Screens/Home';

import Schools from './Screens/Schools';
import Notifications from './Screens/Notifications';

export default () => (

  <Switch>
    
    <Route path="/portal/" exact component={Home} />

    {/* School */}
    <Route path="/portal/schools" exact component={Schools} />

    {/* Notifications */}
    <Route path="/portal/notifications" exact component={Notifications} />
  </Switch>
  
);