import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Header from "./Components/Header";
import Table from "./Components/Table";
import SchoolModal from "../../Modals/School";

export default class extends React.PureComponent {
  static propTypes = {
    showSchoolModal: PropTypes.bool.isRequired,
    selectedSchoolId: PropTypes.number,

    componentDidMount: PropTypes.func.isRequired,
    onCloseSchoolModal: PropTypes.func.isRequired,
    didCreateSchool: PropTypes.func.isRequired,
    didUpdateSchool: PropTypes.func.isRequired,
    didDeleteSchool: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedSchoolId: null
  };

  componentDidMount = () => this.props.componentDidMount();

  render() {
    
    const {
      showSchoolModal,
      selectedSchoolId,

      onCloseSchoolModal,
      didCreateSchool,
      didUpdateSchool,
      didDeleteSchool
    } = this.props;


    return (
      
      <Screen>

        <Header />

        <Table />

        {showSchoolModal && (
          <SchoolModal
            schoolId={selectedSchoolId}
            onClose={onCloseSchoolModal}
            didCreate={didCreateSchool}
            didUpdate={didUpdateSchool}
            didDelete={didDeleteSchool}
          />
        )}

      </Screen>

    )
  }
}
