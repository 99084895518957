import { initialize } from 'redux-form';

import { getNotification } from '../../../HttpRequests/notifications';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch, getState) => {

  const { notificationId } = getProps();
  
  if (notificationId) {

    const response = await getNotification({ id: notificationId });

    const notification = response.data.data;

    dispatch(initialize(FORM_NAME, notification))
    
  } else {

    const { user } = getState();

    dispatch(initialize(FORM_NAME, {
      school_id: user.type === 'School' ? user.school_id : null
    }))

  }

}