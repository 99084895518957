import { createAction, handleActions } from 'redux-actions';

const loadSchools = createAction('SCREENS/SCHOOLS/LOAD_SCHOOLS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadSchools]: (state, action) => action.payload,
  },
  defaultState
);

export { loadSchools, reducer };