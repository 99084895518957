import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    schools: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,

    onChangeTable: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
  }

  render() {

    const {
      schools,
      pagination,
      fetching,

      onChangeTable,
      onClickEdit
    } = this.props;
    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 300,
        render: (text, record) => (
          <a role="presentation" onClick={() => onClickEdit(record)}>
            { text }
          </a>
        )
      },

      {
        title: 'Url',
        dataIndex: 'wordpress_url',
      },
    ]
    return (
      <Table
        dataSource={schools}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />
    )
  }
}