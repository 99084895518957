import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Fields from './Components/Fields';
import Footer from './Components/Footer';

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    schoolId: PropTypes.number,
    
    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    schoolId: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      schoolId,
      onClickCancel
    } = this.props;

    return (
        
      <Modal
        visible
        width={550}
        title={`${schoolId ? 'School': 'New School'}`}
        onCancel={onClickCancel}
        footer={<Footer submitting={submitting} schoolId={schoolId} />}
      >
        
        <Fields submitting={submitting} />

      </Modal>
    )
  }
}