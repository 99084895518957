import create from '../Services/create';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate } = getProps();
  

  const isCreated = await dispatch(create(values));

  if (isCreated) {

    onClose();
    didCreate();
    
  }
  
};
