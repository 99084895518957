import { createAction, handleActions } from 'redux-actions';

const setSelectedNotificationId = createAction("SCREENS/NOTIFICATIONS/SET_SELECTED_NOTIFICATION_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedNotificationId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedNotificationId, reducer };
