import { connect } from 'react-redux';

import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';

import onCloseSchoolModal from './Handlers/onCloseSchoolModal';
import didCreateSchool from './Handlers/didCreateSchool';
import didUpdateSchool from './Handlers/didUpdateSchool';
import didDeleteSchool from './Handlers/didDeleteSchool';

const mapStateToProps = (state) => ({
  showSchoolModal: state.screens.Schools.showSchoolModal,
  selectedSchoolId: state.screens.Schools.selectedSchoolId
});

const actionCreators = {
  componentDidMount,
  onCloseSchoolModal,
  didCreateSchool,
  didUpdateSchool,
  didDeleteSchool
};

export default connect(mapStateToProps, actionCreators)(Component);