import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Header from "./Components/Header";
import Table from "./Components/Table";
import NotificationModal from "../../Modals/Notification";

export default class extends React.PureComponent {
  static propTypes = {
    showNotificationModal: PropTypes.bool.isRequired,
    selectedNotificationId: PropTypes.number,
    componentDidMount: PropTypes.func.isRequired,
    onCloseNotificationModal: PropTypes.func.isRequired,
    didCreateNotification: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedNotificationId: null
  };

  componentDidMount = () => this.props.componentDidMount();

  render() {

    const {
      showNotificationModal,
      selectedNotificationId,
      
      onCloseNotificationModal,
      didCreateNotification
    } = this.props;

    return (

      <Screen>

        <Header />

        <Table />

        {showNotificationModal && (
          <NotificationModal
            notificationId={selectedNotificationId}
            onClose={onCloseNotificationModal}
            didCreate={didCreateNotification}
          />
        )}
        
      </Screen>
      
    );
  }
}
