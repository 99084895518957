import { connect } from 'react-redux';
import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';
import onClickView from './Handlers/onClickView';

const mapStateToProps = (state) => ({
  notifications: state.screens.Notifications.notifications,
  pagination: state.screens.Notifications.pagination,
  fetching: state.screens.Notifications.fetching
});

const actionCreators = {
  onChangeTable,
  onClickView
};

export default connect(mapStateToProps, actionCreators)(Component);
