import { createAction, handleActions } from 'redux-actions';

const loadNotifications = createAction('SCREENS/NOTIFICATIONS/LOAD_NOTIFICATIONS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadNotifications]: (state, action) => action.payload,
  },
  defaultState
);

export { loadNotifications, reducer };