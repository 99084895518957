import { connect } from 'react-redux';

import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import onCloseNotificationModal from './Handlers/onCloseNotificationModal';
import didCreateNotification from './Handlers/didCreateNotification';

const mapStateToProps = (state) => ({
  showNotificationModal: state.screens.Notifications.showNotificationModal,
  selectedNotificationId: state.screens.Notifications.selectedNotificationId
});

const actionCreators = {
  componentDidMount,
  onCloseNotificationModal,
  didCreateNotification
};

export default connect(mapStateToProps, actionCreators)(Component);