import { setProps } from '../props';
import fetchSchools from '../../../Core/Services/schools';
import initialize from '../Services/initialize';

export default (props) => async (dispatch) => {
  
  setProps(props);

  dispatch(fetchSchools());

  dispatch(initialize());
  
}