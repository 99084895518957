import { createSelector } from 'reselect';

const selectPermissions = () => [];
const selectPathname = state => state.router.location.pathname;
const selectUser = state => state.user;

export default createSelector(
  selectPermissions,
  selectPathname,
  selectUser,
  (permissions, pathname, user) => {

    const left = [];
    const right = [];

    if (user && user.type === 'Internal') {

      left.push({
        key: "schools",
        title: 'Schools',
        href: '/portal/schools',
        selected: pathname && pathname.startsWith('/portal/schools')
      });

      left.push({
        key: "notifications",
        title: 'Notifications',
        href: '/portal/notifications',
        selected: pathname && pathname.startsWith('/portal/notifications')
      });

    }

    if (user && user.type === 'School') {

      left.push({
        key: "notifications",
        title: 'Notifications',
        href: '/portal/notifications',
        selected: pathname && pathname.startsWith('/portal/notifications')
      });

    }

    return { left, right };
  }
)